import { graphql } from 'gatsby';
import React from 'react';
import PropTypes from 'prop-types';
import CloudflarePerformance from '../../../containers/Services/Cloudflare/Performance';

const cloudflareData = {
  title: 'Application Performance',
  seoParams: {
    ogImagePath: '/og-images/cloudflare-performance.png',
    ogTitle: 'Application Performance Services | Tech Holding',
    ogDesc: 'Accelerate and Stabilize Your Digital Services with Tech Holding and Cloudflare.',
    ogPath: '/services/cloudflare/performance',
  },
  sections: [
    {
      heading: 'Application Performance',
      subheading: 'Accelerate and Stabilize Your Digital Services',
      description:
        'With Tech Holding and Cloudflare, enhance your application performance through:',
      columns: [
        {
          items: [
            {
              title: 'Content Delivery Network (CDN)',
              content:
                'Distribute your content around the world, so it’s closer to your users, speeding up load times.',
            },
          ],
        },
        {
          items: [
            {
              title: 'DNS Resolution',
              content:
                'Utilize Cloudflare’s lightning-fast DNS to improve the responsiveness of your applications.',
            },
          ],
        },
      ],
    },
  ],
  whyPartner: {
    heading: 'Why Partner with Tech Holding?',
    subheading: 'Expertise, Support, and Innovation',
    description: 'Choose Tech Holding as your Cloudflare MSSP to benefit from:',
    columns: [
      {
        items: [
          {
            title: 'Tailored Solutions',
            content: 'Custom configurations that address your specific business challenges.',
          },
          {
            title: 'Expert Support',
            content:
              'Access to our team of experts who understand your needs and are ready to assist 24/7.',
          },
        ],
      },
      {
        items: [
          {
            title: 'Strategic Growth',
            content:
              'Innovative solutions that scale with your business, helping you stay ahead in a competitive market.',
          },
        ],
      },
    ],
  },
  callToAction: {
    heading: 'Get Started with Tech Holding Today',
    description:
      'Ready to enhance your business with our Cloudflare solutions? Contact us to discuss your needs or schedule a consultation. Our experts are here to help you navigate your digital transformation journey with confidence.',
  },
};

const propTypes = {
  data: PropTypes.shape({
    contentfulCompanyQuote: PropTypes.shape({
      footerDescription: PropTypes.shape({
        json: PropTypes.shape({}).isRequired,
      }).isRequired,
    }),
  }).isRequired,
};

const CloudflarePerformancePage = ({ data }) => (
  <CloudflarePerformance details={cloudflareData} companyQuote={data.contentfulCompanyQuote} />
);

CloudflarePerformancePage.propTypes = propTypes;

export default CloudflarePerformancePage;

export const query = graphql`
  query {
    contentfulCompanyQuote(label: { eq: "servicesAws" }) {
      footerDescription {
        json
      }
    }
  }
`;
